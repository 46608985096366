.genesys-copy {
  font-size: unset
}

@media (min-width: 800px) {
  .genesys-copy {
      font-size: 15px;
  }
}

@media screen and (max-width: 600px),
screen and (max-device-width: 600px),
screen and (max-device-width: 600px) and (orientation: landscape),
screen and (max-device-height: 428px) and (orientation: landscape),
screen and (max-device-width: 711px) and (orientation: landscape) {
  .genesys-text-with-us {
    height: 100%;
    width: 100%;
    max-width: 100% !important;
    right: 0px !important;
    bottom: 0px !important;
  }
}
