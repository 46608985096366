.empty-header-hero {
  margin: auto;
  max-width: 1140px;
  padding-top: 12px;
}

.empty-header-hero .bg-container {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.empty-header-hero .text-inner-wrapper {
  z-index: 1;
  position: relative;
  margin: auto;
  max-width: 1140px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.empty-header-hero .title {
  font-family: raleway;
  font-weight: 400 !important;
}

.empty-header-hero .title {
  font-size: 40px;
  color: #1d4c80 !important;
  line-height: 1em;
  margin-left: 0px !important;
  font-family: raleway;
  font-weight: 400 !important;
  text-align: left;
  margin-bottom: 0px !important;
}

.empty-header-hero .bold-title {
  font-size: 40px;
  color: #1d4c80 !important;
  line-height: 1em;
  margin-left: 0px !important;
  font-weight: bolder !important;
  font-weight: 400 !important;
  text-align: left;
  margin-bottom: 0px !important;
}

.empty-header-hero .text-inner-wrapper .title {
  font-size: 40px;
  color: #1d4c80 !important;
  line-height: 1.12em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  width: inherit;
  padding: 0;
  font-family: raleway;
  font-weight: 900 !important;
  margin-left: 0px !important;
}

@media (max-width: 480px) {
  .empty-header-hero .title {
    margin: 20px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 18px !important;
    height: 32px !important;
    text-align: left;
  }
  .empty-header-hero .desktop-hr {
    display: none;
  }

  .empty-header-hero hr {
    margin: 16px 20px 12px;
    border-bottom: 1px solid rgba(55, 105, 152, 0);
  }

  .empty-header-hero {
    width: auto;
    margin-top: 12px;
    margin-bottom: 8px;
  }
  .empty-header-hero .min-height-90 {
    height: auto !important;
  }
  .empty-header-hero .title {
    font-size: 34px !important;
  }
  .empty-header-hero .left-head-align {
    text-align: left;
    font-size: 34px !important;
    text-align: left;
  }

  .mobile-header {
    margin: 0 !important;
    height: 52px;
    padding: 15px 12px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .mobile-header .title {
    font-size: 34px !important;
  }
}

@media (max-width: 361px) {
  .empty-header-hero .title {
    height: auto !important;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .empty-header-hero .title {
    margin-left: 42px !important;
  }
}
